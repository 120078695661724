<template>
  <div class="app-container recruitpost">
    <div class="titlebox">
      <el-button
        size="medium"
        type="text"
        @click="$router.push({ name: 'recruitmentpostmanagementList' })"
      >返回</el-button>
      <div class="title">添加岗位</div>
    </div>
    <section class="sections-container">
      <el-steps
        space="32%"
        :active="active"
        finish-status="success"
        align-center
        class="stepBox"
      >
        <el-step process-status="process " title="岗位信息"></el-step>
        <el-step process-status="process " title="招聘信息"></el-step>
      </el-steps>
      <el-form ref="form" :model="form" label-width="220px" v-show="active == 0">
        <div class="section-wrap" style="margin-top:20px">
          <h3 class="title-icon">基本信息</h3>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="计划名称"
                prop="planId"
                :rules="{
                  required: true,
                  message: '请选择人力计划',
                  trigger: 'change'
                }"
              >
                <el-select
                  v-model="form.planId"
                  :disabled="$route.params.type=='Info'"

                  class="defautInput"
                  placeholder="请选择人力计划"
                  @change="planTojob"
                >
                  <el-option
                    v-for="(plan, i) in planArr"
                    :key="i"
                    :value="plan.id"
                    :label="plan.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="聘用形式">
                <el-select v-model="form.employType" :disabled="$route.params.type=='Info'" class="defautInput"  placeholder="请选择聘用形式">
                  <el-option
                    v-for="(item, index) in hireFormList"
                    :key="index"
                    :label="item.dictName"
                    :value="item.dictId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="需求部门">
                <el-input v-model="form.orgIdName" class="defautInput"  disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="岗位名称">
                <el-select v-model="form.jobId" :disabled="$route.params.type=='Info'" class="defautInput"  @change="getjobview">
                  <el-option
                    v-for="(job, i) in jobArr"
                    :key="i"
                    :label="job.jobName"
                    :value="job.jobId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="需求人数">
                <el-input v-model="form.number" class="defautInput"  disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作城市" prop="workCity">
                <el-cascader
                  :disabled="$route.params.type=='Info'"
                  :options="cityOptions"
                  v-model="form.workCity"
                  separator=" "
                  filterable
                  class="defautInput"
                  style="width:63%;"
                ></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-form ref="form1" :model="form" label-width="220px" v-if="active == 1">
        <div class="section-wrap">
          <h3 class="title-icon">招聘信息</h3>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="招聘负责人" prop="dutyUser" :rules="{ required: true,message:'请选择招聘负责人',trigger:'change' }">
              <el-select v-model="form.dutyUser" class="defautInput"  placeholder="请选择招聘负责人" clearable filterable :disabled="$route.params.type=='Info'">
                <el-option
                  v-for="(item, index) in dutyUsers"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="开始时间"
              prop="startTime"
              :rules="{ required: true, message: '请选择招聘开始时间' }"
            >
              <el-date-picker
                :disabled="$route.params.type=='Info'"
                v-model="form.startTime"
                :picker-options="pickerOptions"
                class="defautInput"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
                default-time="[00:00:00]"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                :disabled="$route.params.type=='Info'"
                v-model="form.endTime"
                :picker-options="pickerOptions1"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
                type="datetime"
                class="defautInput"
                default-time="[23:59:59]"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
    <section class="sections-container" v-show="active == 0">
      <el-form ref="form" :model="form" label-width="220px">
        <div class="section-wrap">
          <h3 class="title-icon">岗位信息</h3>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="薪酬范围"
                prop="salaryStart"
                :rules="{ required: true, message: '请选择薪酬范围' }"
              >
                <el-select
                  v-model="form.salary"
                  :disabled="$route.params.type=='Info'"
                  style="float: left"
                  class="defautInput"
                  v-show="!custom"
                  @change="changesalary"
                  placeholder="请选择薪酬范围"
                >
                  <el-option
                    v-for="(salary, i) in salaryStartList"
                    :key="i"
                    :label="salary.dictName"
                    :value="salary.dictId"
                  ></el-option>
                </el-select>
                <div v-show="custom" style="float: left;">
                  <el-input style="width:41%" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.salaryStart" :disabled="$route.params.type=='Info'"></el-input>
                  <span style="margin:0 10px">至</span>
                  <el-input style="width:41%" oninput="value=value.replace(/[^\d]/g, '')" v-model="form.salaryEnd" :disabled="$route.params.type=='Info'"></el-input>
                </div>
                <el-button
                  style="margin-left:10px;float: left;"
                  @click="custom = !custom"
                  :disabled="$route.params.type=='Info'"
                  type="text"
                >自定义范围</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="学历要求"
                prop="educationName"
              >
                <el-input v-model="form.educationName" class="defautInput" disabled ></el-input>
                <!--<el-select v-model="form.education" :disabled="$route.params.type=='Info'"  placeholder="请选择学历要求">
                  <el-option
                    v-for="(education, i) in educationList"
                    :key="i"
                    :label="education.label"
                    :value="education.value"
                  ></el-option>
                </el-select>!-->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="工作经验"
                prop="workExperience"
                :rules="{ required: true, message: '请选择工作经验要求' }"
              >
                <el-select v-model="form.workExperience" :disabled="$route.params.type=='Info'" class="defautInput"  placeholder="请选择工作经验要求">
                  <el-option
                    v-for="(workyear, i) in yearsWorkingList"
                    :key="i"
                    :label="workyear.dictName"
                    :value="workyear.dictId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="岗位性质"
                prop="positionQuality"
                :rules="{ required: true, message: '请选择岗位性质' }"
              >
                <el-select v-model="form.positionQuality" :disabled="$route.params.type=='Info'" class="defautInput"  placeholder="请选择岗位性质">
                  <el-option
                      v-for="(item, index) in postNatureList"
                      :key="index"
                      :label="item.dictName"
                      :value="item.dictId"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="紧急程度">
                <el-radio-group v-model="form.emergencyLevel" :disabled="$route.params.type=='Info'">
                  <el-radio label="0">非紧急</el-radio>
                  <el-radio label="1">紧急</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="21">
              <el-form-item
                label="岗位职责"
                prop="dutyText"
                :rules="{
                  required: true,
                  message: '请填写岗位职责',
                  trigger: 'blur'
                }"
              >
                <ck-editor
                  v-model="form.dutyText"
                  ref="CkEditor"
                  :readOnlyMode="$route.params.type=='Info'"
                ></ck-editor>
                <!--v-if="($route.params.type != 'Add' && form.dutyText) || $route.params.type == 'Add'"!-->
              </el-form-item>
            </el-col>
            <el-col :span="21">
              <el-form-item label="岗位技能">
                <el-input type="textarea" rows="4" v-model="form.skill" style="width:100%" :disabled="$route.params.type=='Info'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="21">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  rows="4"
                  :disabled="$route.params.type=='Info'"
                  v-model="form.remarks"
                  style="width:100%"
                  maxlength="1000"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          <el-col :offset="11">
            <el-button @click="$router.back()" v-if="!status">取消</el-button>
            <el-button type="primary" @click="onSubmit()" v-if="!status">下一步</el-button>
          </el-col>
        </el-row>
        </div>
      </el-form>
    </section>
    <section class="sections-container" v-if="active == 1">
      <div class="section-wrap">
        <h3 class="title-icon">模板信息</h3>
      </div>
      <el-form ref="form2" :model="form" label-width="220px">
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="面试邀约模板"
              prop="interviewMould"
              :rules="{ required: true, message: '请选择面试邀约模板',trigger:'change'}"
            >
              <el-select v-model="form.interviewMould"  class="defautInput" placeholder="请选择面试邀约模板" :disabled="$route.params.type=='Info'">
                <el-option
                  v-for="(mould, i) in mouldes"
                  :key="i"
                  :label="mould.emailName"
                  :value="mould.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="offer邮件模板"
              prop="offerMould"
              :rules="{ required: true, message: '请选择offer邮件模板',trigger:'change'}"
            >
              <el-select v-model="form.offerMould"  class="defautInput" placeholder="请选择offer邮件模板" :disabled="$route.params.type=='Info'">
                <el-option
                  v-for="(mould, i) in mouldes"
                  :key="i"
                  :label="mould.emailName"
                  :value="mould.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </section>
    <section class="sections-container" v-show="active == 1">
      <el-form ref="form3" :model="form" label-width="220px">
        <div class="section-wrap">
          <h3 class="title-icon">设置简历通过标准</h3>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="性别">
              <el-select v-model="form.sex"  class="defautInput" placeholder="请选择offer邮件模板" :disabled="$route.params.type=='Info'">
                <el-option value="0" label="不限"></el-option>
                <el-option value="1" label="男"></el-option>
                <el-option value="2" label="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历" prop="educationPass" :rules="{required:true,message:'请选择学历',trigger:'change'}">
              <el-select v-model="form.educationPass"  class="defautInput" placeholder="请选择学历" :disabled="$route.params.type=='Info'">
                <el-option
                  v-for="(item, index) in highestEducationList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄">
              <div style="float: left;" class="defautInput">
                <el-input-number
                  class="ageInput"
                  :disabled="$route.params.type=='Info'"
                  v-model.number="form.ageStart"
                  :min="16"
                  :max="60"
                  :controls="false"
                />
                <span style="margin:0 10px">至</span>
                <el-input-number
                  class="ageInput"
                  :disabled="$route.params.type=='Info'"
                  :controls="false"
                  :max="60"
                  :min="Number(form.ageStart) + 1"
                  v-model.number="form.ageEnd"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作经验" prop="workYear" :rules="{required:true,message:'请选择工作经验',trigger:'change'}">
              <el-select v-model="form.workYear"  class="defautInput" placeholder="请选择工作经验" :disabled="$route.params.type=='Info'">
                <el-option
                  v-for="(item, index) in experienceList"
                  :key="index"
                  :label="item.dictName"
                  :value="item.dictId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="11" style="margin-top:10px">
            <el-button @click="comback" v-if="!status">上一步</el-button>
            <el-button v-show="$route.params.type!='Info'" type="primary" @click="onSubmit" v-if="!status">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </section>
  </div>
</template>

<script>
import CkEditor from '@/components/CkEditor';
import { planList, planDetatil } from '@/api/manpowerplanning';
import {
  recruitStationSave,
  selectPrincipallist,
  recruitStationDetail,
  emailList
} from '../../../api/recruitment_allocation';
export default {
  name: 'Addhiring',
  components: {
    CkEditor
  },
  data() {
    return {
      active: 0,
      inputVisible: false,
      status: false,
      cityOptions: window.$cityList,
      edit: false,
      custom: false,
      planArr: [],
      hireFormList: [],
      jobArr: [],
      educationList: [],
      dutyUsers: [],
      yearsWorkingList: [],
      highestEducationList: [],
      salaryStartList: [],
      mouldes: [],
      postNatureList: [],
      experienceList: [],
      departPeopleCount: '',
      form: {
        planId: '',
        workCity: [],
        skill: '',
        positionQuality: '',
        salaryStart: '',
        workExperience: '',
        workYear: '',
        remarks: '',
        emergencyLevel: '0',
        orgIdName: '',
        salaryEnd: '',
        dutyUser: '',
        employType: '',
        educationPass: '',
        dutyText: '',
        offerMould: '',
        interviewMould: '',
        jobId: '',
        sex: '0',
        education: '',
        educationName: '',
        ageStart: '16',
        ageEnd: '60',
        workStart: '1',
        workEnd: '3',
        educational: '',
        startTime: '',
        endTime: '',
        number: ''
      },
      pickerOptions: {
        disabledDate: (time) => time.getTime() + 86400000 < new Date()
      },
      pickerOptions1: {
        disabledDate: (time) => {
          if (!this.form.startTime) {
            return time.getTime() + 86400000 < new Date();
          }
            return time.getTime() < new Date(this.form.startTime);

        }
      }
    };
  },
  computed: {
    dict() {
      return this.$store.state.app.dict;
    }
  },
  methods: {
    async getDetail(id) {
      recruitStationDetail({ id }).then(async (result) => {
        if (result && !result.code) {
          const res = await planDetatil({ id: result.planId });

          this.form.orgIdName = res.orgIdName;
          this.jobArr = [];
          res.basicRequestList.map((it) => {
            it.jobId = String(it.jobId);
            this.jobArr.push(it);
          });
          if (this.$route.params.type != 'Copy') {
            this.form = result;
            this.form.orgIdName = res.orgIdName;
            this.form.workCity = [result.provinceCode, result.cityCode];
            this.getjobview(result.jobId, true);
          } else {
            for (const key in result) {
              for (const key1 in this.form) {
                if (key1 != 'jobId' && key1 != 'education' && key1 != 'workCity' && key1 != 'dutyText' && key == key1) {
                  this.form[key1] = result[key];
                }
              }
            }
            this.form.workCity = [result.provinceCode, result.cityCode];
          }
        }
      });
    },
    changesalary(val) {
      this.form.salaryStart = val.split(',')[0];
      this.form.salaryEnd = val.split(',')[1];
    },
    onSubmit() {
      if (this.active < 1) {
        if (Number(this.form.salaryStart) > Number(this.form.salaryEnd)) {
          this.$message.warning('薪酬范围请从最低到最高填写');
          return false;
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.active++;
            selectPrincipallist({}).then((res) => {
              this.dutyUsers = [];
              res.map((it) => {
                this.dutyUsers.push({
                  label: `${it.staffName} ${
                    it.officeName ? `(${it.officeName})` : ''
                  }`,
                  value: String(it.staffId)
                });
              });
            });
            emailList({}).then((res) => {
              this.mouldes = [];
              if (res.emailLisDtoList) {
                res.emailLisDtoList.map((it) => {
                  it.id = String(it.id);
                  this.mouldes.push(it);
                });
              }
            });
          }
        });
      } else {
        this.$refs['form1'].validate((valid) => {
          if (valid) {
            this.$refs['form2'].validate((valid) => {
              if (valid) {
                this.$refs['form3'].validate((valid) => {
                  if (valid) {
                    const params = {
                      ...this.form,
                      provinceCode: this.form.workCity[0],
                      cityCode: this.form.workCity[1]
                    };

                    recruitStationSave(params).then((res) => {
                      if (res.code == 0) {
                        this.$message.success('招聘岗位操作成功');
                        this.$router.push({name: 'recruitmentpostmanagementList'});
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    },
    comback() {
      if (this.active == 1) {this.active--;} else {this.$router.back();}
    },
    planTojob(val) {
      this.form.jobId = '';
      planDetatil({ id: val }).then((res) => {
        this.jobArr = [];
        this.form.orgIdName = res.orgIdName;
        res.basicRequestList.map((it) => {
          it.jobId = String(it.jobId);
          this.jobArr.push(it);
        });
      });
    },
    getjobview(val, boolean) {
      this.jobArr.map((it) => {
        if (it.jobId == val) {
          this.educationList = [];
          this.form.number = it.number;
          this.form.education = it.educationDemand;
          this.form.educationName = it.educationDemandName;
          this.form.skill = it.skillDemand;
          if (!boolean) {
            this.form.dutyText = it.jobSpecification;
            this.$refs.CkEditor.setContent(it.jobSpecification);
          } else {
            this.$refs.CkEditor.setContent(this.form.dutyText);
          }
        }
      });
    }
  },
  mounted() {
    this.postNatureList = this.$store.state.app.dict.filter((item) => item.groupId == 'postNature');
    this.highestEducationList = this.$store.state.app.dict.filter((item) => item.groupId == 'highestEducation');
    this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'hireForm');
    this.yearsWorkingList = this.$store.state.app.dict.filter((item) => item.groupId == 'yearsWorking');
    this.experienceList = this.$store.state.app.dict.filter((item) => item.groupId == 'experience');
    this.salaryStartList = this.$store.state.app.dict.filter((item) => item.groupId == 'salaryStart');
    this.$nextTick(function() {
      planList({ pageNo: 1, pageSize: 50000, status: 6 }).then((res) => {
        res.list.map((item) => {
          item.id = String(item.id);
          this.planArr.push(item);
        });
        if (this.$route.params.id && Number(this.$route.params.id)) {
          this.custom = true;
          this.getDetail(this.$route.params.id);
        }
      });
    });
  }
};
</script>

<style lang="scss">
.recruitpost {
  label{
    font-weight: normal;
  }
}
.chart-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 84px);
}
.sections-container {
  overflow: hidden;
  margin-bottom: 20px;
  .title-icon {
    font-size: 16px;
    margin: 0 0 20px 30px;
    &:before {
      display: inline-block;
      width: 8px;
      height: 18px;
      background: #498df0;
      content: " ";
      border-radius: 5px;
      vertical-align: top;
      margin-right: 5px;
    }
  }
  .stepBox {
    transform:translateX(20%);
    .is-process,.is-success {
      .el-step__icon.is-text {
        background-color:#498DF0;
        color: white;
        border: none;
      }
    .el-icon-check:before {
      content: "1";
      }
    }
    .el-step__title.is-process,.el-step__title.is-success {
      color:#498DF0;
    }
  }
}
.sections-container {
  border-radius: 4px;
  padding: 40px 60px;
  background-color: #fff;
}
.ageInput {
  width: 43%;
}
.recruitpost {
  .defautInput,
  .el-form-item__content .el-date-editor {
    width: 63%;
  }
}

@media (max-width: 1250px){
  .ageInput {
    width: 40%;
  }
.el-form-item__content .el-date-editor {
    width: 73%;
  }
}
.titlebox {
  text-align: center;
  line-height: 17px;
  background: #fff;
  font: bold 18px/25px "Microsoft YaHei";
  padding: 14px 40px;
  margin-bottom: 20px;
  > .el-button {
    float: left;
    color: #484848;
    transform: translateY(-8px);
    font-size: 16px;
  }
}
</style>
